<template>
  <div class="WJgroupHonor">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/info/banner.png" alt="">
        </div>
      </el-col>
    </el-row>
    <div>
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class=" WJgroupIntroduce-Box" type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div class="padding-tb70">
            <div class="WJgroup-titleBox flex justify_between">
              <p class="WJgroup-title">{{PageTitle}}</p>
              <div class="flex">
                <el-date-picker v-model="year" size="mini" style="width: 140px;"  type="year"
                  value-format='yyyy' placeholder="筛选年份"> </el-date-picker>
                <el-input v-model="keywords" size="mini" style="width: 140px;margin: 0 10px;" placeholder="请输入内容"
                  clearable />
                <el-button size="mini" type="primary" style="background: #AF8E68;border-color: #AF8E68;">搜索</el-button>
              </div>
              <!-- <p class="WJgroup-subtitle">WEIGA Group Introduction</p> -->
            </div>
          </div>
          <div>
            <el-row class=" " :gutter='50' type="flex" justify="space-between" align='center'>
              <el-col v-for="(item,index) in recommendList" :key="index" :xs="23" :sm="23" :md="23" :lg="8" :xl="8">
                <div class="news-TopRow" @click="gotoDetail(item)">
                  <div class="news-TopRow-img"> <img :src="item.litpic" alt=""> </div>
                  <div class="news-TopRow-time flex justify_between">
                    <h5 class="flex "><img src="../../assets/image/info/timeicon.png" alt=""> {{item.add_time}} </h5>
                    <p class="activep" @click="gotoDetail()"> <i class="el-icon-right"></i> </p>
                  </div>
                  <div class="news-TopRow-title">{{item.title}}</div>
                </div>
              </el-col>

            </el-row>

          </div>
          <div>
            <ul class="news-ul">
              <li class="news-li flex justify_between" v-for="(item,index) in newsList" :key="index" @click="gotoDetail(item)">
                <div class="news-litime flex flex_column justify_center">
                  <h5>{{item.add_time.substr(8,2)}}</h5>
                  <h6>{{item.add_time.substr(0,7)}}</h6>
                </div>
                <div class="news-licontent">
                  <h1 class="el">{{item.title}}</h1>
                  <h2 class="eltwo">
                    {{item.seo_description}}
                  </h2>
                </div>
                <div class="news-liarrow">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </li>
            </ul>

            <div class="flex paginationDiv">
              <el-pagination background @current-change="handleCurrentChange" :current-page.sync="pageNo"
                :page-size="pageSize" layout="total, prev, pager, next" :total="total">
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <footerb></footerb>

  </div>
</template>

<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {

        PageTitle: '课堂展示',
        menuActiveIndex: '/personnelCadreSchool',
        menu: {
          name: '人才窗口',
          path: '/personneltalentConcept',
          list: [{
            name: '人才理念',
            path: '/personneltalentConcept',
          }, {
            name: '干部培训学校',
            path: '/personnelCadreSchool',
          }, {
            name: '加入我们',
            path: '/personnelJoinUs',
          }, ]
        },
        breadcrumb: [{
          name: '人才窗口',
          path: '/personneltalentConcept',
        }, {
          name: '干部培训学校',
          path: '/personnelCadreSchool',
        }, {
          name: '课堂展示',
          path: '/moreSchool',
        }],


        type:null,
        total: 0,
        keywords:null,
        year:null,
        recommendList:[],
        newsList:[],
        pageNo:1,
        pageSize:10,
        typeMap:{
          1:79,
          2:80,
          3:81
        },
        typeid:79,
      };
    },

    created() {
      this.typeid=139
      this.getroute()

    },
    methods: {
      resetCondition(){
        this.total=0;
        this.pageNo=1;
        this.year=null
        this.keywords=null;
        this.newsList=[];
        this.recommendList=[];
      },
      getData(){
        if(this.refresh){
          this.resetCondition();
        }
        this.refresh=false;
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        let newsCondition =`ekey=1&typeid=${this.typeid}&page=${this.pageNo}&pagesize=${this.pageSize}`;
        if(this.keywords){
          newsCondition =newsCondition+'&keywords='+this.keywords
        }
        this.$request.post(api,{
          // apiArclist_1:`ekey=1&typeid=${this.typeid}&flag=c&limit=100`,//获取栏目推荐文章
          apiList_1:newsCondition
        }).then(res=>{
          // this.recommendList =res.apiArclist[1].data
          this.newsList =res.apiList[1].data;
          this.total = res.apiList[1].total;
        })
      },
      gotoDetail(item){
        this.$router.push({
          path:'/InformationDetail',
          query:{type:this.typeid,id:item.aid}
        })
      },
      getroute() {
        this.refresh=true;
        this.getData();
      },
      handleCurrentChange() {
        this.getData();
      },
      choseYears(index) {
        this.yearIndex = index
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>

<style lang="less">
  .margin-lf {
    margin-left: 30px;
    margin-right: 30px;
  }

  .news-TopRow {
    cursor: pointer;
    padding: 0px 0 60px;
  }

  .news-TopRow:hover .news-TopRow-time p {
    // background: #AF8E68 !important;
    color: #AF8E68 !important;

  }

  .news-TopRow:hover .news-TopRow-title {
    color: #AF8E68 !important;

  }

  .news-TopRow-img {
    width: 100%;
    height: 280px;
    margin-bottom: 20px;
  }

  .news-TopRow-img img {
    width: 100%;
    height: 100%;
  }

  .news-TopRow-time {}

  .news-TopRow-time h5 {
    line-height: 38px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }

  .news-TopRow-time img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .news-TopRow-time p {
    // width: 94px;
    // height: 40px;
    // background: #CCCCCC;
    border-radius: 2px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ccc;
    line-height: 40px;
    text-align: center;
  }



  .news-TopRow-title {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 57px;
    height: 57px;
    width: 100%;
  }

  .news-ul .news-li {
    padding: 22px 0;
    border-top: 1px solid #ccc;
  }

  .news-ul .news-li:last-child {
    border-bottom: 1px solid #ccc;
  }

  .news-ul .news-li:hover .news-litime {
    background: #AF8E68;
    color: #fff;
  }

  .news-ul .news-li:hover .news-licontent h1 {
    // text-decoration: underline;
    color: #AF8E68;
  }

  .news-ul .news-li:hover .news-liarrow i {
    font-weight: bold;
    color: #B08E68 !important;
  }

  .news-ul .news-li .news-litime {
    width: 90px;
    height: 90px;
    color: #AAAAAA;
    background: #EEEEEE;
    border-radius: 2px;
    text-align: center;
    transition: 0.5s;
  }

  .news-ul .news-li .news-litime h5 {
    font-size: 52px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 54px;
  }

  .news-ul .news-li .news-litime h6 {

    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
  }

  .news-ul .news-li .news-liarrow i {
    font-size: 20px;
    color: #CCCCCC;

  }

  .news-ul .news-li .news-licontent {
    flex: 1;
    padding: 0 40px;
    cursor: pointer;
  }

  .news-ul .news-li .news-licontent h1 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    height: 20px;
    width: 100%;
    transition: 0.5s;
  }

  .news-ul .news-li .news-licontent h2 {
    margin-top: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    height: 48px;
    width: 100%;
  }

  .paginationDiv {
    justify-content: center;
    padding: 30px 0;
  }

  .paginationDiv .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #AF8E68 !important;
  }



</style>
